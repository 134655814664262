import React from "react";
import "./aboutUs.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import ReactLogo from "./profilePictures/testimage.png"

function Derrick() {
  const navigate = useNavigate();
  function returnHome() {
    navigate("/about");
  }
  return (
    <div className="Derrick">
      <body id = "Body">
        <div><h1 id = "Name">Derrick Ng</h1></div>
        <div id="infoBox">
        <div id = "divBackground">
          
          <h3>Position:</h3>
          <p>Back End Lead</p>

          <h3>Year:</h3>
          <p>Senior</p>

          <h3>About Me: </h3>
          <p>
            Hello everyone, my name is Derrick and I am currently a senior. I am from 
            Daly City and my hobbies include walking my dog, bouldering, and 
            eating good food.
          </p>

          <Button id = "daSquad" onClick={returnHome}><h2>Get to know the rest of us!</h2> </Button>
        </div>
        <img id="profilePic" src={ReactLogo} alt="Picture of Derrick Ng"/>
        </div>
      </body>
    </div>
  );
}

export default Derrick;
