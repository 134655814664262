import React from "react";
import { useNavigate } from "react-router-dom";
import "./mainpage.css";

export default function Checkout() {
    const navigate = useNavigate();
    
    function finishCheckout() {
        //could have confirmation page
        navigate("/");
    }

  return (
    <div>
      <div id="shipping">
        <h2>Shipping Address</h2>
        <input type="text" placeholder="First Name" />
        <input type="text" placeholder="Last Name" />
        <input type="text" placeholder="Street Address" />
        <input type="text" placeholder="Street Address 2 (optional)" />
        <input type="text" placeholder="City" />
        <input type="text" placeholder="State" />
        <input type="text" placeholder="Zip Code" />
      </div>
      <div id="payment">
        <h2>Payment</h2>
        <input type="text" placeholder="Card Number" />
        <input type="text" placeholder="Expires" />
        <input type="text" placeholder="Security Code" />
        <input type="text" placeholder="Name on Card" />
        <button onClick={finishCheckout} id="pay">Pay</button>
      </div>
      
    </div>
  );
}
