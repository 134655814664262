import React from "react";
import "./aboutUs.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import ReactLogo from "./profilePictures/LuisEspinosa.jpg";

const proPic = ReactLogo;





function Luis() {
 const navigate = useNavigate();
 function returnHome() {
   navigate("/about");
 }
 return (
   <div className="Luis">
     <body id = "Body">
       <h1 id = "Name">Luis Espinosa</h1>
       <div id="infoBox">
         <div id = "divBackground">


           <h3>Position:</h3>
           <p>Front-end Master</p>


           <h3>Year:</h3>
           <p>Senior</p>


           <h3>About Me: </h3>
           <p>
             Hi! I'm Luis Espinosa, I am from San Francisco and I am majoring in Computer Science at SF State University.
             I am a first generation college student who is passionate about educating and learning, exposing myself to new experiences,
             and creating content! I love music, working out, creating moments with family and friends, watching sports, playing games,
             and I'm looking forward to creating opportunities to connect with others!
           </p>


           <Button id = "daSquad" onClick={returnHome}><h2>Get to know the rest of us!</h2> </Button>
         </div>
         <img id="profilePic" src={ReactLogo} alt="Picture of Luis Espinosa"/>
       </div>
     </body>
   </div>
 );
}


export default Luis;