import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./members/about";
import Derrick from "./members/Derrick";
import Griffin from "./members/Griffin";
import Leonardo from "./members/Leonardo";
import Luis from "./members/Luis";
import Robin from "./members/Robin";
import Saarth from "./members/Saarth";
import Brandon from "./members/Brandon";
import SignUp from "./SignUp";
import Mainpage from "./mainpage";
import MainpageBaseball from "./otherMains/mainpageBaseball";
import MainpageFootball from "./otherMains/mainpageFootball";
import MainpageSoccer from "./otherMains/mainpageSoccer";
import MainpageHockey from "./otherMains/mainpageHockey";
import MainpageF1 from "./otherMains/mainpageF1";
import MainpageBaseketBall from "./otherMains/mainpageBasketBall";
import PublishProduct from "./newProduct";
import Login from "./login";
import AccountPage from "./AccountPage";

import BarterPage from "./BarterPage";
import PurchasePage from "./PurchasePage";
import Seller from "./Seller";
import ProductDetails from "./components/ProductDetails";
import BarterProduct from "./components/BarterProduct";
import Checkout from "./Checkout";
 
//derrick's temp pagess
// import UserForm from "./UserForm.tsx"
// import ProductForm from "./ProductForm.tsx"

// https://reactrouter.com/en/main/start/examples
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Mainpage />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/Leonardo" element={<Leonardo />} />
          <Route path="/about/Griffin" element={<Griffin />} />
          <Route path="/about/Robin" element={<Robin />} />
          <Route path="/about/Derrick" element={<Derrick />} />
          <Route path="/about/Saarth" element={<Saarth />} />
          <Route path="/about/Brandon" element={<Brandon />} />
          <Route path="/about/Luis" element={<Luis />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/mainpageBaseball" element={<MainpageBaseball />} />
          <Route path="/mainpageFootball" element={<MainpageFootball />} />
          <Route path="/mainpageSoccer" element={<MainpageSoccer />} />
          <Route path="/mainpageHockey" element={<MainpageHockey />} />
          <Route path="/mainpageF1" element={<MainpageF1 />} />
          <Route path="/mainpageBasketBall" element={<MainpageBaseketBall />} />
          <Route path="/publishProduct" element={<PublishProduct />} />
          <Route path="/login" element={<Login />} />
          <Route path="/AccountPage" element={<AccountPage />} />
          <Route path="/BarterPage" element={<BarterPage />} /> 
          <Route path="/PurchasePage" element={<PurchasePage />} />
          <Route path="/Seller" element={<Seller />} />

          <Route path="/product/:productId" element={<ProductDetails />}/>
          <Route path="/BarterProduct/:productId" element={<BarterProduct />}/>
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
