import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./Banners/TLRTB.png";
import "./mainpage.css";

function PurchasePage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [userId, setUserId] = useState(""); // Add state for userId.
  const [cartProductNames, setCartProductNames] = useState([]);
  const [cartProductPrices, setCartProductPrices] = useState([]);
  const [cartProductImages, setCartProductImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [barterOffers, setBarterOffers] = useState([]);
  const [productInformation, setProductInformation] = useState({});
  useEffect(() => {
    // Function to retrieve the username from the JWT token
    const getUsernameFromToken = () => {
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in local storage

      // Decode the JWT token to extract user information
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the payload
        setUsername(decodedToken.username); // Extract the username from the decoded token
        setUserId(decodedToken.userId);
      }
    };

    getUsernameFromToken();
  }, []);

  useEffect(() => {
    // Function to authenticate token on component mount
    const authenticateToken = async () => {
      try {
        const response = await fetch(
          // "http://localhost:3000/auth-check",
          "https://thelockerroom.world:3000/auth-check",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 401) {
          // Unauthorized, clear token and redirect to login page
          localStorage.removeItem("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
      }
    };

    authenticateToken(); // Call the authentication function on component mount
  }, [navigate]);


  useEffect(() => {
    // Fetch barter offers associated with the seller's products
    axios
      // .get("http://localhost:3000/getBuyerBarterOffers", {
      .get("https://thelockerroom.world:3000/getBuyerBarterOffers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setBarterOffers(response.data.barterOffers);
      })
      .catch((error) => {
        setErrorMessage("Error fetching barter offers: " + error.message);
      });
  }, []);

  useEffect(() => {
    // Fetch product information for each offer
    const fetchProductInformation = async (productId) => {
      try {
        const response = await axios.get(
          // `http://localhost:3000/getSpecificProduct/${productId}`
          `https://thelockerroom.world:3000/getSpecificProduct/${productId}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching product information:", error);
        throw error;
      }
    };

    const fetchAllProductInformation = async () => {
      const productInfo = {};
      const productIds = [
        ...new Set(
          barterOffers.flatMap((offer) => [
            offer.origin_product_id,
            offer.offer_product_id,
          ])
        ),
      ];

      for (const productId of productIds) {
        productInfo[productId] = await fetchProductInformation(productId);
      }

      setProductInformation(productInfo);
    };

    if (barterOffers.length > 0) {
      fetchAllProductInformation();
    }
  }, [barterOffers]);

  useEffect(() => {
    // Function to retrieve the user information from the backend
    const getUserInfo = async () => {
      try {
        // Make a GET request to fetch user information based on user ID

        // const response = await axios.get(
        //   `http://localhost:3000/getUser/${userId}`
        // );
        const response = await axios.get(
        `https://thelockerroom.world:3000/getUser/${userId}`
        );

        // Get the cart from the response, remove extra characters, and split into an array
        let stringCart = response.data.cart
          .replace(/\[|\]|,|\"/g, "")
          .split(" ");

        for (let element of stringCart) {
          try {
            let cartNameResponse = await axios.get(
              // `http://localhost:3000/getCartProducts/${element}`
              `https://thelockerroom.world:3000/getCartProducts/${element}`
            );
            cartProductNames.push(cartNameResponse.data[0].name);
            cartProductPrices.push(cartNameResponse.data[0].price);
            cartProductImages.push(cartNameResponse.data[0].image);
          } catch (error) {
            console.log("Error fetching cart information:", error);
          }
        }

        console.log(cartProductNames);
        console.log(cartProductPrices);

        setCartProductNames(cartProductNames);
        setCartProductPrices(cartProductPrices);
        setCartProductImages(cartProductImages);
        setTotalCartPrice(
          cartProductPrices.reduce((a, b) => a + b, 0) * 1.0725
        );

        setIsLoading(false);
        // setCart(cartProductNames)
        // setCart(response.data.cart); // Set cart from the response
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    };

    getUserInfo(); // Call the function to fetch user information
  }, [userId]);

  const handleLogout = async () => {
    try {
      // Make a POST request to the logout endpoint
      // const response = await fetch("http://localhost:3000/logout", {
        const response = await fetch("https://thelockerroom.world:3000/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        // Clear the JWT token from local storage
        localStorage.removeItem("token");
        // Redirect the user to the login page
        window.location.reload();
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  function handleSearch(event) {
    setSearchQuery(event.target.value);
  }

  function redirectToCheckout() {
    navigate("/checkout");
  }

  function redirectToBaseball() {
    navigate("/mainpageBaseball");
  }
  function redirectToBasketball() {
    navigate("/mainpageBasketball");
  }
  function redirectToFootball() {
    navigate("/mainpageFootball");
  }
  function redirectToSoccer() {
    navigate("/mainpageSoccer");
  }
  function redirectToHockey() {
    navigate("/mainpageHockey");
  }
  function redirectToF1() {
    navigate("/mainpageF1");
  }
  function redirectToAbout() {
    navigate("/about");
  }
  function redirectToSignUp() {
    navigate("/signUp");
  }

  function redirectToPublishProduct() {
    navigate("/publishProduct");
  }

  function redirectToLogin() {
    navigate("/login");
  }

  function redirectToAccountPage() {
    navigate("/AccountPage");
  }

  function redirectToListingPage() {
    navigate("/ListingPage");
  }

  function redirectToBarterPage() {
    navigate("/BarterPage");
  }

  function redirectToPurchasePage() {
    navigate("/PurchasePage");
  }

const getStatusStyle = (status) => {
  switch (status) {
    case "In review":
      return { backgroundColor: "#3399FF", color: "#333333" }; // Less bright blue
    case "Accepted":
      return { backgroundColor: "#339966", color: "#333333" }; // Less bright green
    case "Declined":
      return { backgroundColor: "#CC3333", color: "#333333" }; // Less bright red
    default:
      return {}; // Default style
  }
};



  return (
    <div className="Account">
      <header>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>The Locker Room - Account</title>
        <link rel="stylesheet" href="styles.css" />
      </header>
      <body>
        <header id="sportsTitle">
          <div id="accountLinks">
            <a
              className="accountLink"
              onClick={redirectToSignUp}
              style={{ display: username ? "none" : "inline" }}
            >
              Sign Up
            </a>
            <a
              className="accountLink"
              onClick={redirectToLogin}
              style={{ display: username ? "none" : "inline" }}
            >
              Log In
            </a>
            <a
              className="accountLink"
              onClick={handleLogout}
              style={{ display: username ? "inline" : "none" }}
            >
              Log Out
            </a>
            <a
              className="accountLink"
              onClick={redirectToAccountPage}
              style={{ display: username ? "inline" : "none" }}
            >
              Account
            </a>
          </div>
          <a href="/">
            <img id="logo" src={Logo}></img>
            <h1>The Locker Room</h1>
            <h3>- Sports Wear -</h3>
          </a>
        </header>
        <nav>
          <a onClick={redirectToBaseball}>Baseball</a>
          <a onClick={redirectToBasketball}>Basketball</a>
          <a onClick={redirectToFootball}>Football</a>
          <a onClick={redirectToHockey}>Hockey</a>
          <a onClick={redirectToF1}>F1 Racing</a>
          <a onClick={redirectToSoccer}>Soccer</a>
          <a onClick={redirectToAbout}>About Us</a>
          <a onClick={redirectToPurchasePage}>Cart</a>
          <a
            onClick={redirectToPublishProduct}
            style={{ display: username ? "inline" : "none" }}
          >
            Publish Product
          </a>
        </nav>

        <div id="cartBox">
          <div id="scrollContainer">
            <h2>Cart</h2>
            {isLoading ? (
              <p>Loading...</p> // Show loading message while data is being fetched
            ) : (
              cartProductNames.map((productName, index) => (
                <div key={index} id="productCard">
                  <img
                    src={cartProductImages[index]}
                    alt={`Prod. ${index + 1}`}
                    id="productImage"
                  />
                  <div id="productInfo">
                    <p id="productName">{productName}</p>
                    <p id="productPrice">${cartProductPrices[index]}</p>
                  </div>
                </div>
              ))
            )}
            <h2>Barter Offers</h2>
            <div className="barter-offers-container">
              {barterOffers.map((offer) => (
                <div key={offer.id} className="barter-offer-item">
                  <div className="offer-box">
                    <div className="product-details left">
                      <p>Product you want:</p>
                      <div>
                        <img
                          src={
                            productInformation[offer.origin_product_id]?.image
                          }
                          alt={
                            productInformation[offer.origin_product_id]?.name
                          }
                          className="product-image"
                        />
                        <div className="product-info">
                          <p>
                            Name:{" "}
                            {productInformation[offer.origin_product_id]?.name}
                          </p>
                          <p>
                            Description:{" "}
                            {
                              productInformation[offer.origin_product_id]
                                ?.description
                            }
                          </p>
                          <p>
                            Price: $
                            {productInformation[offer.origin_product_id]?.price}
                          </p>
                          <p>
                            Quantity On stock:{" "}
                            {
                              productInformation[offer.origin_product_id]
                                ?.quantity
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-details right">
                    <p>Product you offered:</p>
                    <div>
                      <img
                        src={productInformation[offer.offer_product_id]?.image}
                        alt={productInformation[offer.offer_product_id]?.name}
                        className="product-image"
                      />
                      <div className="product-info">
                        <p>
                          Name:{" "}
                          {productInformation[offer.offer_product_id]?.name}
                        </p>
                        <p>
                          Description:{" "}
                          {
                            productInformation[offer.offer_product_id]
                              ?.description
                          }
                        </p>
                        {offer.status === "Accepted" ? (
                          <p style={getStatusStyle(offer.status)}>
                            Good news! Your offer has been accepted. 😊
                          </p>
                        ) : offer.status === "Declined" ? (
                          <p style={getStatusStyle(offer.status)}>
                            Sorry, you offer has been rejected. 😞
                          </p>
                        ) : (
                          <p style={getStatusStyle(offer.status)}>
                            In Review. Please wait for confirmation. 🕒
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id="checkout">
            <p onClick={redirectToCheckout}>
              Total (after tax): {totalCartPrice}
            </p>
            <button onClick={redirectToCheckout}>Checkout</button>
          </div>
        </div>
        <footer>
          <p>&copy; 2024 The Locker Room - Sports Wear</p>
        </footer>
      </body>
    </div>
  );
}

export default PurchasePage;
