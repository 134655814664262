import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../Banners/TLRTB.png";
import "../mainpage.css";
import "./ProductDetails.css";

export default function ProductDetails() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const { productId } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Function to retrieve the username from the JWT token
    const getUsernameFromToken = () => {
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in local storage

      // Decode the JWT token to extract user information
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the payload
        setUsername(decodedToken.username); // Extract the username from the decoded token
      }
    };

    getUsernameFromToken();
  }, []);

  useEffect(() => {
    // Function to authenticate token on component mount
    const authenticateToken = async () => {
      try {
        const response = await fetch(
          // "http://localhost:3000/auth-check",
          "https://thelockerroom.world:3000/auth-check",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 401) {
          // Unauthorized, clear token and redirect to login page
          localStorage.removeItem("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
      }
    };

    authenticateToken(); // Call the authentication function on component mount
  }, [navigate]);

  const handleBarterClick = async () => {
    navigate(`/BarterProduct/${productId}`); // Pass productId as a parameter
  };

  const handleLogout = async () => {
    try {
      // Make a POST request to the logout endpoint
      // const response = await fetch("http://localhost:3000/logout", {
      const response = await fetch("https://thelockerroom.world:3000/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        // Clear the JWT token from local storage
        localStorage.removeItem("token");
        // Redirect the user to the login page
        window.location.reload();
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  //////////////////////

  useEffect(() => {
    async function getProduct() {
      try {
        const response = await axios.get(
          // `http://localhost:3000/getSpecificProduct/${productId}`
          `https://thelockerroom.world:3000/getSpecificProduct/${productId}`
        );
        setProduct(response.data);
        console.log(response.data);
      } catch (error) {
        console.log("error, view details fail", error);
      }
    }
    getProduct();
  }, [productId]);

  const addToCart = async () => {
    try {
      const token = localStorage.getItem("token"); // Assuming you're storing the token in localStorage
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(
        // "http://localhost:3000/addToCart",
        "https://thelockerroom.world:3000/addToCart",
        { productId },
        config
      );
      alert("Product added to cart successfully");
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  if (!product) {
    return (
      <h1>
        Product is null. Either the query is wrong or the GET request is wrong
      </h1>
    );
  }

  function redirectToBaseball() {
    navigate("/mainpageBaseball");
  }
  function redirectToBasketball() {
    navigate("/mainpageBasketball");
  }
  function redirectToFootball() {
    navigate("/mainpageFootball");
  }
  function redirectToSoccer() {
    navigate("/mainpageSoccer");
  }
  function redirectToHockey() {
    navigate("/mainpageHockey");
  }
  function redirectToF1() {
    navigate("/mainpageF1");
  }
  function redirectToAbout() {
    navigate("/about");
  }
  function redirectToSignUp() {
    navigate("/signUp");
  }

  function redirectToPublishProduct() {
    navigate("/publishProduct");
  }

  function redirectToLogin() {
    navigate("/login");
  }

  function redirectToAccountPage() {
    navigate("/AccountPage");
  }

  function redirectToBarterPage() {
    navigate("/BarterPage");
  }

  function redirectToPurchasePage() {
    navigate("/PurchasePage");
  }

  return (
    <div className="ListingPage">
      <header>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>The Locker Room - Listing</title>
        <link rel="stylesheet" href="styles.css" />
      </header>
      <body>
        <header id="sportsTitle">
          <div id="accountLinks">
            <a
              className="accountLink"
              onClick={redirectToSignUp}
              style={{ display: username ? "none" : "inline" }}
            >
              Sign Up
            </a>
            <a
              className="accountLink"
              onClick={redirectToLogin}
              style={{ display: username ? "none" : "inline" }}
            >
              Log In
            </a>
            <a
              className="accountLink"
              onClick={handleLogout}
              style={{ display: username ? "inline" : "none" }}
            >
              Log Out
            </a>
            <a
              className="accountLink"
              onClick={redirectToAccountPage}
              style={{ display: username ? "inline" : "none" }}
            >
              Account
            </a>
          </div>
          <a href="/">
            <img id="logo" src={Logo}></img>
            <h1>The Locker Room</h1>
            <h3>- Sports Wear -</h3>
          </a>
          <h3>Welcome back {username}</h3>
        </header>
        <nav>
          <a onClick={redirectToBaseball}>Baseball</a>
          <a onClick={redirectToBasketball}>Basketball</a>
          <a onClick={redirectToFootball}>Football</a>
          <a onClick={redirectToHockey}>Hockey</a>
          <a onClick={redirectToF1}>F1 Racing</a>
          <a onClick={redirectToSoccer}>Soccer</a>
          <a onClick={redirectToAbout}>About Us</a>
          <a onClick={redirectToBarterPage}>Barter</a>
          <a onClick={redirectToPurchasePage}>Cart</a>
          <a
            onClick={redirectToPublishProduct}
            style={{ display: username ? "inline" : "none" }}
          >
            Publish Product
          </a>
        </nav>

        <div className="ProductDetailBox">
          <p>{product.name}</p>

          <img src={product.image} alt={product?.name} />

          <p>{product.description}</p>

          <p>Price: ${product.price}</p>

          <p>Quantity: {product.quantity}</p>

          <button
            id="AddToCart"
            onClick={addToCart}
            style={{ display: username ? "inline" : "none" }}
          >
            Add to cart
          </button>
          <button
            id="AddToCart"
            onClick={redirectToLogin}
            style={{ display: username ? "none" : "inline" }}
          >
            Log In to Add to Cart
          </button>
          <button
            onClick={handleBarterClick}
            style={{ display: username ? "inline" : "none" }}
          >
            Barter
          </button>
        </div>

        <footer>
          <p>&copy; 2024 The Locker Room - Sports Wear</p>
        </footer>
      </body>
    </div>
  );
}
