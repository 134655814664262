import React from "react";
import "./aboutUs.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import ReactLogo from "./profilePictures/testimage.png"

function Griffin() {
  const navigate = useNavigate();
  function returnHome() {
    navigate("/about");
  }
  return (
    <div className="Griffin">
      <body id = "Body">
      <div><h1 id = "Name">Griffin Wattles</h1></div>
      <div id="infoBox">
        <div id = "divBackground">
          
          <h3>Position:</h3>
          <p>Git Master</p>

          <h3>Year:</h3>
          <p>Senior</p>
          
          <h3>About Me:</h3>
          <p>
            Hello, my name is Griffin and I am a senior student at the university.
            I've lived in california most of my life, either in los angeles or
            mountain view. My hobbies are reading, drawing, and playing video games
            with my friends.
          </p>

          <Button id = "daSquad" onClick={returnHome}><h2>Get to know the rest of us!</h2> </Button>
        </div>
        <img id="profilePic" src={ReactLogo} alt="Picture of Griffin Waffles"/>
        </div>
      </body>
    </div>
  );
}

export default Griffin;
