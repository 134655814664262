import React from "react";
import "./aboutUs.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import ReactLogo from "./profilePictures/robin.jpg"

function Robin() {
  const navigate = useNavigate();
  function returnHome() {
    navigate("/about");
  }
  return (
    <div className="Robin">
      <body id = "Body">
      <div><h1 id = "Name">Robin Pilapil</h1></div>
      <div id="infoBox">
        <div id = "divBackground">
        <h3>Position:</h3>
          <p>Scrum Master</p>

          <h3>Year:</h3>
          <p>Senior</p>

          <h3>About Me:</h3>
          <p>
            Hi! I'm Robin Pilapil and I am currently a senior. I was raised in
            Pacifica, California and I enjoy drawing, crocheting, taking walks
            and going to the gym.
          </p>

          <Button id = "daSquad" onClick={returnHome}><h2>Get to know the rest of us!</h2> </Button>
        </div>
        <img id="profilePic" src={ReactLogo} alt="Picture of Robin Pilapil"/>
        </div>
      </body>
    </div>
  );
}

export default Robin;
