import React from "react";
import "./aboutUs.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import ReactLogo from "./profilePictures/leo.jpeg"

function Leonardo() {
  const navigate = useNavigate();
  function returnHome() {
    navigate("/about");
  }
  return (
    <div className="Leonardo">
      <body id = "Body">
      <div><h1 id = "Name">Leonardo Meza</h1></div>
      <div id="infoBox">
        <div id = "divBackground">
        <h3>Position:</h3>
          <p>Team Lead</p>

          <h3>Year:</h3>
          <p>Senior</p>

          <h3>About Me:</h3>
          <p>
            Hello everyone, my name is Leonardo and I am a senior student. Also
            I am an internationl transfer student, I am from Aguascalientes,
            Mexico but before coming to SFSU I was already studying abroad in
            Taiwan, so I transferred from there indeed. My passions are auto
            racing, especially Formula 1, and I also enjoy going out with friends,
            have drinks and just explore in general!!
          </p>

          <Button id = "daSquad" onClick={returnHome}><h2>Get to know the rest of us!</h2> </Button>
        </div>
        <img id="profilePic" src={ReactLogo} alt="Picture of Leonardo Meza"/>
        </div>
      </body>
    </div>
  );
}

export default Leonardo;
