import Button from "@mui/material/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./about.css";

function About() {
  const navigate = useNavigate();

  function redirectToBrandon() {
    navigate("/about/Brandon");
  }
  function redirectToLeonardo() {
    navigate("/about/Leonardo");
  }
  function redirectToGriffin() {
    navigate("/about/Griffin");
  }
  function redirectToRobin() {
    navigate("/about/Robin");
  }
  function redirectToDerrick() {
    navigate("/about/Derrick");
  }
  function redirectToSaarth() {
    navigate("/about/Saarth");
  }
  function redirectToLuis() {
    navigate("/about/Luis");
  }
  function redirectToMainpage(){
    navigate("/");
  }

  return (
    <div className="About">
      <header>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>About us</title>
        <link rel="stylesheet" href="styles.css" />
      </header>
      <body id = "aboutBody">
      <h1 id = "aboutHeader">Get to know the members of our team:</h1>
      <div>
        <ul>
          <li>
            <Button id = "meza" onClick={redirectToLeonardo}>Leonardo Meza</Button>
          </li>
          <li>
            <Button id = "griffin" onClick={redirectToGriffin}>Griffin Wattles</Button>
          </li>
          <li>
            <Button id = "robin" onClick={redirectToRobin}>Robin Pilapil</Button>
          </li>
          <li>
            <Button id = "derrick" onClick={redirectToDerrick}>Derrick Ng</Button>
          </li>
          <li>
            <Button id = "saarth" onClick={redirectToSaarth}>Saarth Kurle</Button>
          </li>
          <li>
            <Button id = "brandon" onClick={redirectToBrandon}>Brandon Johnson</Button>
          </li>
          <li>
            <Button id = "luis" onClick={redirectToLuis}>Luis Espinosa</Button>
          </li>
          <li>
            <Button id = "mainpage" onClick={redirectToMainpage}>main page</Button>
          </li>
        </ul>
      </div>
      <footer></footer>
      </body>
    </div>
  );
}

export default About;