import React from "react";
import "./aboutUs.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import ReactLogo from "./profilePictures/brandon.jpg"

function Brandon() {
  const navigate = useNavigate();
  function returnHome() {
    navigate("/about");
  }
  return (
    <div className="Brandon">
      <body id = "Body">
      
      <div><h1 id = "Name">Brandon Johnson</h1></div>
      <div id="infoBox">
      <div id = "divBackground">

          <h3>Position:</h3>
          <p>Front End Master</p>

          <h3>Year:</h3>
          <p>Senior</p>

          <h3>About Me: </h3>
          <p>
            Hello! My name is Brandon Johnson. I'm from San Jose. I'm actually on the San Francisco
            State University Cross Country team. My favorite things to do are relax, hang out with people, and 
            one of my biggest hobbies is video games. 
          </p>

          <Button id = "daSquad" onClick={returnHome}><h2>Get to know the rest of us!</h2> </Button>
        </div>
        <img id="profilePic" src={ReactLogo} alt="Picture of Brandon Johnson"/>
        </div>
      </body>
    </div>
  );
}

export default Brandon;
