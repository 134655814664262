import axios from "axios"; // Import Axios
import React, { useEffect, useState } from "react"; // Import React hooks
import { useNavigate } from "react-router-dom";
import "../mainpage.css";
import ReactLogo from "../Banners/warriors.webp";
import Logo from "../Banners/TLRTB.png"

function MainpageBaseketBall() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(""); // Add state for userId
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSeller, setIsSeller] = useState(""); // Add state for isSeller

  useEffect(() => {
    // Function to retrieve the username from the JWT token
    const getUsernameFromToken = () => {
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in local storage

      // Decode the JWT token to extract user information
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the payload
        setUsername(decodedToken.username); // Extract the username from the decoded token
        setUserId(decodedToken.userId); // Extract the userId from the decoded token
      }
    };

    getUsernameFromToken();
  }, []);

  useEffect(() => {
    // Function to authenticate token on component mount
    const authenticateToken = async () => {
      try {
        const response = await fetch(
          // "http://localhost:3000/auth-check",
          "https://thelockerroom.world:3000/auth-check",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 401) {
          // Unauthorized, clear token and redirect to login page
          localStorage.removeItem("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
      }
    };

    authenticateToken(); // Call the authentication function on component mount
  }, [navigate]);

  useEffect(() => {
    // Function to retrieve the user information from the backend
    const getUserInfo = async () => {
      try {
        // Make a GET request to fetch user information based on user ID

        // const response = await axios.get(`http://localhost:3000/getUser/${userId}`);
        const response = await axios.get(
          `https://thelockerroom.world:3000/getUser/${userId}`
        );

        setIsSeller(response.data.is_seller); // Set is_seller from the response
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    };

    getUserInfo(); // Call the function to fetch user information
  }, [userId]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        // const response = await axios.get("http://localhost:3000/getProducts");
        const response = await axios.get(
          "https://thelockerroom.world:3000/getProducts"
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }

    // fetchUserInfo();
    fetchProducts();
  }, [navigate]); //

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleLogout = async () => {
    try {
      // Make a POST request to the logout endpoint
      // const response = await fetch("http://localhost:3000/logout", {
        const response = await fetch("https://thelockerroom.world:3000/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        // Clear the JWT token from local storage
        localStorage.removeItem("token");
        // Redirect the user to the login page
        window.location.reload();
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  function handleSearch(event) {
    setSearchQuery(event.target.value);
  }

  let filteredProducts = products.filter(
    (product) =>
      (product.name.toLowerCase().includes("basketball") ||
        product.description.toLowerCase().includes("basketball") ||
        product.category.toLowerCase().includes("basketball")) &&
      !product.category.toLowerCase().includes("barter")
  );

  if (searchQuery != "") {
    filteredProducts = filteredProducts.filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }

  function redirectToBaseball() {
    navigate("/mainpageBaseball");
  }
  function redirectToBasketball() {
    navigate("/mainpageBasketball");
  }
  function redirectToFootball() {
    navigate("/mainpageFootball");
  }
  function redirectToSoccer() {
    navigate("/mainpageSoccer");
  }
  function redirectToHockey() {
    navigate("/mainpageHockey");
  }
  function redirectToF1() {
    navigate("/mainpageF1");
  }
  function redirectToAbout() {
    navigate("/about");
  }
  function redirectToSignUp() {
    navigate("/signUp");
  }

  function redirectToPublishProduct() {
    navigate("/publishProduct");
  }

  function redirectToLogin() {
    navigate("/login");
  }

  function redirectToAccountPage() {
    navigate("/AccountPage");
  }

  function redirectToSalesDashboard() {
    navigate("/Seller");
  }

  function redirectToListingPage() {
    navigate("/ListingPage");
  }

  function redirectToBarterPage() {
    navigate("/BarterPage");
  }

  function redirectToPurchasePage() {
    navigate("/PurchasePage");
  }

  return (
    <div className="mainpageBasketball">
      <header>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>The Locker Room</title>
        <link rel="stylesheet" href="styles.css" />
      </header>
      <body>
        <header id="sportsTitle">
          <div id="accountLinks">
            <input
              className="SearchContext"
              type="text"
              placeholder="Search products..."
              value={searchQuery}
              onChange={handleSearch}
              style={{ width: "25%" }}
            />
            <a
              className="accountLink"
              onClick={redirectToSignUp}
              style={{ display: username ? "none" : "inline" }}
            >
              Sign Up
            </a>
            <a
              className="accountLink"
              onClick={redirectToLogin}
              style={{ display: username ? "none" : "inline" }}
            >
              Log In
            </a>
            <a
              className="accountLink"
              onClick={handleLogout}
              style={{ display: username ? "inline" : "none" }}
            >
              Log Out
            </a>
            <a
              className="accountLink"
              onClick={redirectToAccountPage}
              style={{ display: username ? "inline" : "none" }}
            >
              Account
            </a>
          </div>
          <a href="/">
            <img id="logo" src={Logo}></img>
            <h1>The Locker Room</h1>
            <h3>- Sports Wear -</h3>
          </a>
        </header>

        <nav>
          <a onClick={redirectToBaseball}>Baseball</a>
          <a onClick={redirectToBasketball}>Basketball</a>
          <a onClick={redirectToFootball}>Football</a>
          <a onClick={redirectToHockey}>Hockey</a>
          <a onClick={redirectToF1}>F1 Racing</a>
          <a onClick={redirectToSoccer}>Soccer</a>
          <a onClick={redirectToAbout}>About Us</a>

          <a
            onClick={redirectToPurchasePage}
            style={{ display: username ? "inline" : "none" }}
          >
            Cart
          </a>
          <a
            onClick={redirectToPublishProduct}
            style={{ display: username ? "inline" : "none" }}
          >
            Publish Product
          </a>
          <a
            onClick={redirectToSalesDashboard}
            style={{ display: isSeller === "YES" ? "inline" : "none" }}
          >
            Sales Dashboard
          </a>
        </nav>

        <div className="banner">
          <img
            id="Banners"
            src={ReactLogo}
            alt="Picture of a Basketball court"
          />
        </div>
        <div className="grid-container">
          {filteredProducts.map((product) => (
            <div className="grid-item" key={product.id}>
              <img
                className="productImage"
                src={product.image}
                alt={product.name}
                onClick={() => handleProductClick(product.id)}
              />
              <h2
                className="productName"
                onClick={() => handleProductClick(product.id)}
              >
                {product.name}
              </h2>
              <p>{product.description}</p>
              <p>Price: ${product.price}</p>
              <p>Quantity: {product.quantity}</p>
            </div>
          ))}
        </div>

        <footer>
          <p>&copy; 2024 The Locker Room - Sports Wear</p>
        </footer>
      </body>
    </div>
  );
}

export default MainpageBaseketBall;
