import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SignUp.css";

function SignUp() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSeller, setIsSeller] = useState("NO"); 
  const [errorMessage, setErrorMessage] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");

  const navigate = useNavigate();

  function RedirectHome() {
    navigate("/");
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    // const response = await axios.post("http://localhost:3000/newUser", {
      const response = await axios.post("https://thelockerroom.world:3000/newUser", {
      username,
      email,
      password,
      isSeller, // Include isSeller in the request body
    });

    if (response.status === 200) {
      console.log("User signed up successfully");
      setUsername("");
      setEmail("");
      setPassword("");
      setRegistrationStatus("Registration successful!");
    } else {
      console.error("Unexpected status code:", response.status);
      setErrorMessage("Sign up failed. Please try again.");
    }
  } catch (error) {
    console.error("Error signing up user:", error);
    setErrorMessage("Sign up failed. Please try again.");
  }
};


  const handleIsSellerChange = (e) => {
    setIsSeller(e.target.checked ? "YES" : "NO"); // Update isSeller state
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {registrationStatus && (
        <p className="success-message">{registrationStatus}</p>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <div className="form-group enable-seller">
          <label className="seller-label" htmlFor="isSeller">
            <input
              type="checkbox"
              id="isSeller"
              checked={isSeller === "YES"}
              onChange={handleIsSellerChange}
            />
            <span>Enable seller account</span>
          </label>
        </div>

        <button type="submit">Submit</button>
        <button type="home" onClick={RedirectHome}>
          Home
        </button>
      </form>
    </div>
  );
}

export default SignUp;
