import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Seller.css";
import "./mainpage.css";

function Seller() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(""); // Add state for userId
  const [errorMessage, setErrorMessage] = useState("");
  const [sellerProducts, setProducts] = useState([]);
  const [barterOffers, setBarterOffers] = useState([]);
  const [productInformation, setProductInformation] = useState({});
  const [isSeller, setIsSeller] = useState(""); // Add state for isSeller

  useEffect(() => {
    // Function to retrieve the username from the JWT token
    const getUsernameFromToken = () => {
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in local storage

      // Decode the JWT token to extract user information
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the payload
        setUsername(decodedToken.username); // Extract the username from the decoded token
        setUserId(decodedToken.userId); // Extract the userId from the decoded token
      }
    };

    getUsernameFromToken();
  }, []);

  useEffect(() => {
    // Function to authenticate token on component mount
    const authenticateToken = async () => {
      try {
        const response = await fetch(
          // "http://localhost:3000/auth-check",
          "https://thelockerroom.world:3000/auth-check",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 401) {
          // Unauthorized, clear token and redirect to login page
          localStorage.removeItem("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
      }
    };

    authenticateToken(); // Call the authentication function on component mount
  }, [navigate]);

  useEffect(() => {
    // Function to retrieve the user information from the backend
    const getUserInfo = async () => {
      try {
        // Make a GET request to fetch user information based on user ID

        // const response = await axios.get(`http://localhost:3000/getUser/${userId}`);
        const response = await axios.get(
          `https://thelockerroom.world:3000/getUser/${userId}`
        );

        setIsSeller(response.data.is_seller); // Set is_seller from the response
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    };

    getUserInfo(); // Call the function to fetch user information
  }, [userId]);

  const handleLogout = async () => {
    try {
      // Make a POST request to the logout endpoint
      // const response = await fetch("http://localhost:3000/logout", {
      const response = await fetch("https://thelockerroom.world:3000/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        // Clear the JWT token from local storage
        localStorage.removeItem("token");
        // Redirect the user to the login page
        window.location.reload();
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    // Fetch products associated with the logged-in seller
    axios
      // .get("http://localhost:3000/getUserProducts", {
      .get("https://thelockerroom.world:3000/getUserProducts", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Replace YOUR_AUTH_TOKEN with the actual token
        },
      })
      .then((response) => {
        // Filter out products with 'Barter' category
        const filteredProducts = response.data.filter(
          (product) => product.category !== "Barter"
        );
        setProducts(filteredProducts);
      })
      .catch((error) => {
        setErrorMessage("Error fetching products: " + error.message);
      });

    // Fetch barter offers associated with the seller's products
    axios
      // .get("http://localhost:3000/getUserBarterOffers", {
      .get("https://thelockerroom.world:3000/getUserBarterOffers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setBarterOffers(response.data.barterOffers);
      })
      .catch((error) => {
        setErrorMessage("Error fetching barter offers: " + error.message);
      });
  }, []);

  useEffect(() => {
    // Fetch product information for each offer
    const fetchProductInformation = async (productId) => {
      try {
        // const response = await axios.get(`http://localhost:3000/getSpecificProduct/${productId}`);
        const response = await axios.get(
          `https://thelockerroom.world:3000/getSpecificProduct/${productId}`
        );

        return response.data;
      } catch (error) {
        console.error("Error fetching product information:", error);
        throw error;
      }
    };

    const fetchAllProductInformation = async () => {
      const productInfo = {};
      const productIds = [
        ...new Set(
          barterOffers.flatMap((offer) => [
            offer.origin_product_id,
            offer.offer_product_id,
          ])
        ),
      ];

      for (const productId of productIds) {
        productInfo[productId] = await fetchProductInformation(productId);
      }

      setProductInformation(productInfo);
    };

    if (barterOffers.length > 0) {
      fetchAllProductInformation();
    }
  }, [barterOffers]);

  function RedirectHome() {
    navigate("/");
  }

  function acceptOffer(offerId) {
    axios
      // .put(`http://localhost:3000/acceptOffer/${offerId}`, null, {
        .put(`https://thelockerroom.world:3000/acceptOffer/${offerId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        // Update the status of the specific offer in the frontend
        const updatedOffers = barterOffers.map((offer) => {
          if (offer.id === offerId) {
            return { ...offer, status: "Accepted" };
          }
          return offer;
        });
        setBarterOffers(updatedOffers);
      })
      .catch((error) => {
        console.error("Error accepting offer:", error);
      });
  }

  function declineOffer(offerId) {
    axios
      // .put(`http://localhost:3000/declineOffer/${offerId}`, null, {
      .put(`https://thelockerroom.world:3000/declineOffer/${offerId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        // Update the status of the specific offer in the frontend
        const updatedOffers = barterOffers.map((offer) => {
          if (offer.id === offerId) {
            return { ...offer, status: "Declined" };
          }
          return offer;
        });
        setBarterOffers(updatedOffers);
      })
      .catch((error) => {
        console.error("Error declining offer:", error);
      });
  }

  function redirectToBaseball() {
    navigate("/mainpageBaseball");
  }
  function redirectToBasketball() {
    navigate("/mainpageBasketball");
  }
  function redirectToFootball() {
    navigate("/mainpageFootball");
  }
  function redirectToSoccer() {
    navigate("/mainpageSoccer");
  }
  function redirectToHockey() {
    navigate("/mainpageHockey");
  }
  function redirectToF1() {
    navigate("/mainpageF1");
  }
  function redirectToAbout() {
    navigate("/about");
  }
  function redirectToSignUp() {
    navigate("/signUp");
  }

  function redirectToPublishProduct() {
    navigate("/publishProduct");
  }

  function redirectToLogin() {
    navigate("/login");
  }

  function redirectToAccountPage() {
    navigate("/AccountPage");
  }

  function redirectToSalesDashboard() {
    navigate("/Seller");
  }

  function redirectToListingPage() {
    navigate("/ListingPage");
  }

  function redirectToBarterPage() {
    navigate("/BarterPage");
  }

  function redirectToPurchasePage() {
    navigate("/PurchasePage");
  }

  return (
    <body>
      <header id="sportsTitle">
        <div id="accountLinks">
          <a
            className="accountLink"
            onClick={redirectToSignUp}
            style={{ display: username ? "none" : "inline" }}
          >
            Sign Up
          </a>
          <a
            className="accountLink"
            onClick={redirectToLogin}
            style={{ display: username ? "none" : "inline" }}
          >
            Log In
          </a>
          <a
            className="accountLink"
            onClick={handleLogout}
            style={{ display: username ? "inline" : "none" }}
          >
            Log Out
          </a>
          <a
            className="accountLink"
            onClick={redirectToAccountPage}
            style={{ display: username ? "inline" : "none" }}
          >
            Account
          </a>
        </div>
        <a href="/">
          <h1>The Locker Room - Sport Dashboard</h1>
        </a>
        <h3>Welcome back {username}</h3>
      </header>
      <nav>
        <a onClick={redirectToBaseball}>Baseball</a>
        <a onClick={redirectToBasketball}>Basketball</a>
        <a onClick={redirectToFootball}>Football</a>
        <a onClick={redirectToHockey}>Hockey</a>
        <a onClick={redirectToF1}>F1 Racing</a>
        <a onClick={redirectToSoccer}>Soccer</a>
        <a onClick={redirectToAbout}>About Us</a>
        <a onClick={redirectToBarterPage}>Barter</a>
        <a onClick={redirectToPurchasePage}>Cart</a>
        <a
          onClick={redirectToPublishProduct}
          style={{ display: username ? "inline" : "none" }}
        >
          Publish Product
        </a>
        <a
          onClick={redirectToSalesDashboard}
          style={{ display: isSeller === "YES" ? "inline" : "none" }}
        >
          Sales Dashboard
        </a>
      </nav>

      <div className="ContainerContainer">
        {errorMessage && <p className="seller-error-message">{errorMessage}</p>}
        <h1 className="seller-header">Your Listings</h1>
        <div className="seller-products-container">
          {sellerProducts.map((product) => (
            <div key={product.id} className="seller-product-item">
              <img
                src={product.image}
                alt={product.name}
                className="seller-product-image"
              />
              <div className="seller-product-details">
                <h3>{product.name}</h3>
                <p>Description: {product.description}</p>
                <p>Price: ${product.price}</p>
                <p>Quantity On stock: {product.quantity}</p>
              </div>
            </div>
          ))}
        </div>
        <h2 className="barter-header">Barter Offers</h2>
        <div className="barter-offers-container">
          {barterOffers.map((offer) => (
            <div key={offer.id} className="barter-offer-item">
              <div className="offer-box">
                <div className="product-details left">
                  <p>Origin Product Information:</p>
                  <div>
                    <img
                      src={productInformation[offer.origin_product_id]?.image}
                      alt={productInformation[offer.origin_product_id]?.name}
                      className="product-image"
                    />
                    <div className="product-info">
                      <p>
                        Name:{" "}
                        {productInformation[offer.origin_product_id]?.name}
                      </p>
                      <p>
                        Description:{" "}
                        {
                          productInformation[offer.origin_product_id]
                            ?.description
                        }
                      </p>
                      <p>
                        Price: $
                        {productInformation[offer.origin_product_id]?.price}
                      </p>
                      <p>
                        Quantity On stock:{" "}
                        {productInformation[offer.origin_product_id]?.quantity}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-details right">
                <p>Offer Product Information:</p>
                <div>
                  <img
                    src={productInformation[offer.offer_product_id]?.image}
                    alt={productInformation[offer.offer_product_id]?.name}
                    className="product-image"
                  />
                  <div className="product-info">
                    <p>
                      Name: {productInformation[offer.offer_product_id]?.name}
                    </p>
                    <p>
                      Description:{" "}
                      {productInformation[offer.offer_product_id]?.description}
                    </p>
                    {offer.status === "In review" && (
                      <div>
                        <button onClick={() => acceptOffer(offer.id)}>
                          Accept
                        </button>
                        <button onClick={() => declineOffer(offer.id)}>
                          Decline
                        </button>
                      </div>
                    )}
                    {offer.status !== "In review" && (
                      <p
                        className={`status-label ${offer.status.toLowerCase()}`}
                      >
                        {offer.status}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <footer>
        <p>&copy; 2024 The Locker Room - Sports Wear</p>
      </footer>
    </body>
  );
}

export default Seller;
