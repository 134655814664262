import React from "react";
import "./aboutUs.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import ReactLogo from "./profilePictures/saarth.jpg"

function Saarth() {
  const navigate = useNavigate();
  function returnHome() {
    navigate("/about");
  }
  return (
    <div className="Saarth">
    <body id = "Body">
    <div><h1 id = "Name">Saarth Kurle</h1></div>
    <div id="infoBox">
      <div id = "divBackground">
          

          <h3>Position:</h3>
          <p>Back-end Master</p>
          <h3>Year:</h3>
          <p>Senior</p>

          <h3>About Me:</h3>
          <p>
            Hey, my name is Saarth Kurle and I am the back-end Master. 
            I am an international student from India and this is my Senior year. I like 
            running, hiking, watching MMA and Soccer.
          </p>

          <Button id = "daSquad" onClick={returnHome}><h2>Get to know the rest of us!</h2> </Button>
        </div>
        <img id="profilePic" src={ReactLogo} alt="Picture of Saarth Kurle"/>
        </div>
      </body>
    </div>
  );
}

export default Saarth;
