import axios from "axios";
import React, { useEffect, useState } from "react"; // Import React hooks
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./newProduct.css";

function ProductForm() {
  const { register, handleSubmit } = useForm();
  const [userId, setId] = useState("");
  const navigate = useNavigate();
  const CATEGORY_OPTIONS = [
    "Baseball",
    "Basketball",
    "Football",
    "Hockey",
    "F1 Racing",
    "Soccer",
    "Other",
  ];
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    // Function to retrieve the username from the JWT token
    const getUserIdFromToken = () => {
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in local storage

      // Decode the JWT token to extract user information
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the payload
        setId(decodedToken.userId); // Extract the username from the decoded token
      }
    };

    getUserIdFromToken();
  }, []);

  useEffect(() => {
    // Function to authenticate token on component mount
    const authenticateToken = async () => {
      try {
        const response = await fetch(
          // "http://localhost:3000/auth-check",
          "https://thelockerroom.world:3000/auth-check",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 401) {
          // Unauthorized, clear token and redirect to login page
          localStorage.removeItem("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
      }
    };

    authenticateToken(); // Call the authentication function on component mount
  }, [navigate]);

  function RedirectHome() {
    navigate("/");
  }

  const onSubmit = async (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (data[key] instanceof FileList) {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    }
    formData.append('userId', userId);
    try {
      // const response = await axios.post('http://localhost:3000/newProduct', formData, {
            const response = await axios.post(
              "https://thelockerroom.world:3000/newProduct",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
      alert("Product added successfully");
      navigate('/'); // or wherever you want to redirect the user after upload
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Failed to add product");
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="outer-container">
      <div className="product-form-container">
        <form
          onSubmit={handleSubmit((data) =>
            onSubmit({ ...data, category: selectedCategory })
          )}
        >
          <label htmlFor="">Product Name</label>
          <input type="text" placeholder="product name" {...register("name")} />
          <label htmlFor="">Description</label>
          <input
            type="text"
            placeholder="description"
            {...register("description")}
          />

          <label htmlFor="">Price</label>
          <input
            type="number"
            min=".01"
            step="any"
            placeholder="price"
            {...register("price")}
          />

          <label htmlFor="">Quantity</label>
          <input
            type="number"
            min="1"
            placeholder="quantity"
            {...register("quantity")}
          />

          <label htmlFor="">Image</label>
          <input type="file" {...register("image")} />

          <label htmlFor="">Category</label>
          <select value={selectedCategory} onChange={handleCategoryChange}>
            <option value={null}>Select Category</option>
            {CATEGORY_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="submit">Submit</button>
          <button type="home" onClick={RedirectHome}>
            Home
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProductForm;


