import axios from "axios";
import React, { useEffect, useState } from "react"; // Import React hooks
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams hook
import "../newProduct.css";

//ux improvement: show product they are bartering for
export default function BarterProduct() {
  const { register, handleSubmit } = useForm();
  const [userId, setId] = useState("");
  const [productId, setProductId] = useState("");
  const [productInfo, setProductInfo] = useState(null);
  const [imageFile, setImageFile] = useState(null); // State to hold the image file

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    // Function to retrieve the username from the JWT token
    const getUserIdFromToken = () => {
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in local storage

      // Decode the JWT token to extract user information
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the payload
        setId(decodedToken.userId); // Extract the username from the decoded token
      }
    };

    getUserIdFromToken();
  }, []);

  useEffect(() => {
    // Function to authenticate token on component mount
    const authenticateToken = async () => {
      try {
        const response = await fetch(
          // "http://localhost:3000/auth-check",
          "https://thelockerroom.world:3000/auth-check",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 401) {
          // Unauthorized, clear token and redirect to login page
          localStorage.removeItem("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
      }
    };

    authenticateToken(); // Call the authentication function on component mount
  }, [navigate]);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]); // Set the image file when input changes
  };

  useEffect(() => {
    // Set the productId from URL parameters
    if (params.productId) {
      setProductId(params.productId);
      // Fetch product information based on productId
      axios
        // .get(`http://localhost:3000/getSpecificProduct/${params.productId}`)
        .get(`https://thelockerroom.world:3000/getSpecificProduct/${params.productId}`)
        .then((response) => {
          setProductInfo(response.data); // Set the productInfo state with fetched data
        })
        .catch((error) => {
          console.error("Error fetching product information:", error);
        });
    }
  }, [params.productId]);

  useEffect(() => {
    // Set the productId from URL parameters
    if (params.productId) {
      setProductId(params.productId);
    }
  }, [params.productId]);

  function RedirectHome() {
    navigate("/");
  }

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("userId", userId);
      formData.append("image", imageFile); // Append image file to form data

      await axios.post(
        // `http://localhost:3000/newBarter/${productId}`,
        `https://thelockerroom.world:3000/newBarter/${productId}`,
        formData
      ); // Include formData as the data payload
      alert("Barter added successfully");
    } catch (error) {
      console.error("Error adding Barter:", error);
      alert("Failed to add Barter");
    }
  };

  return (
    <div className="outer-container">
      {/* <div className="product-form-container"> */}
      {productInfo && ( // Check if productInfo is available before rendering
        <div className="product-form-container">
          <h2>Bartering for: </h2>
          <img className="barter-image" src={productInfo.image} alt="Product" />
          <p>Name: {productInfo.name}</p>
          <p>Description: {productInfo.description}</p>

          {/* Render other product information as needed */}
        </div>
      )}
      <div className="product-form-container">
        <h2>Create a Barter</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="">Product Name</label>
          <input type="text" placeholder="product name" {...register("name")} />

          <label htmlFor="">Description</label>
          <input
            type="text"
            placeholder="description"
            {...register("description")}
          />

          <label htmlFor="">Image</label>
          <input type="file" onChange={handleImageChange} />

          <button type="submit">Submit</button>
          <button type="home" onClick={RedirectHome}>
            Home
          </button>
        </form>
      </div>
      {/* </div> */}
    </div>
  );
}
